window.addEventListener("DOMContentLoaded", () => {
  const icons = document.querySelectorAll(".copy-icon")
  icons.forEach((icon) =>
    icon.addEventListener("click", (e) => {
      navigator.clipboard.writeText(e.target.dataset.copy)

      e.target.innerText = e.target.dataset.copied
      setTimeout(() => {
        e.target.innerText = e.target.dataset.text
      }, 300)
    })
  )
})
