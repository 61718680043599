// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("channels")
require("bootstrap")

import Rails from "@rails/ujs"

import mountComponents from "../util/mountComponents"

Rails.start()

// Make all images available for image_pack_tag
require.context("../images", true)

// Add all component CSS files to bundle (required for components with no JS files)
//require.context("../components", true, /(?<=\.scss)$/) // Only include scss files

// Add application wide CSS, we do this after the components
// because we want classes to override default component styling
require("../stylesheets/application.scss")

// Make components available for react_component helper
const componentsContext = require.context(
  "../components",
  true,
  /(?<!(test|test\.[jt]sx?|\.scss))$/ // Exclude tests and scss files
)
mountComponents(componentsContext)

require("../helpers/copyToClipboard")
require("../helpers/accountNumberWarning")
require("../helpers/tooltips")
require("../helpers/organizationUsersForm")
require("../helpers/smsOtpAutofill")
